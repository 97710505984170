import React from "react";
import "./Navbar.css";

import Logo from "../../assets/Image/logo.png";
import { Link, NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <>
      <div className="navbar">
        <div className="nav-logo">
          <Link to="/">
            <img src={Logo} alt="" width="40px" className="logo" />
          </Link>
          <h1 className="titel">Apex clothing</h1>
        </div>

        <ul className="nav-menu">
          <li>
            <NavLink className="icon" to="/">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink width={1000} to="/about">
              Contact
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
