import React from 'react'

 import "./Hero.css"
 import {
  
  AiOutlineFacebook,
AiOutlineInstagram,
AiOutlineWhatsApp
} from "react-icons/ai";
const Hero = () => {
  return (
  <div className='main'> 
   <div className='content'>
    <h1 className='hero-h1'>Welcome to Apex Clothing</h1>
    <p className='hero-p'>Web Site</p>
    <ul className='hero-ul'>
      <li><a className='social-icon' href="https://www.facebook.com/profile.php?id=61552750238764&mibextid=ZbWKwL"><AiOutlineFacebook /></a> </li>
      <li><a className='social-icon' href="https://instagram.com/apexclothingcorporate?utm_source=qr&igshid=MzNlNGNkZWQ4Mg=="><AiOutlineInstagram /></a> </li>
      <li><a className='social-icon' href="https://wa.me/770334650"><AiOutlineWhatsApp /></a> </li>
     

    </ul>

   </div>
  </div>
   
   
  )
}

export default Hero
