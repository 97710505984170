import React from "react";
import "./About.css";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
const About = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_7gyy464",
        "template_he6p0vu",
        form.current,
        "UErY6_0mL0aPld-QP"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <div className="ab-main">
      <div className="ab-content">
        <h1 className="cont-h1">Contact</h1>
        <form ref={form} onSubmit={sendEmail} className="contact">
          <input type="text" name="user_name" id="" placeholder="NAME" />

          <input type="text" name="user_email" id="" placeholder="EMAIL" />

          <textarea
            name="message"
            id=""
            cols="30"
            rows="10"
            placeholder="YOUR MESSAGE"
          ></textarea>

          <button type=" submit" className="btn">
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default About;
