import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./page/Home";

import About from "./page/About";
import Navbar from "./components/Navbar/Navbar";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
         <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
